// @flow
import React, { Fragment, useState } from 'react'
// $FlowFixMe
import {
	ChartPieIcon,
	UsersIcon,
	ChatBubbleBottomCenterTextIcon,
	InboxArrowDownIcon,
	EnvelopeIcon,
	CreditCardIcon,
	// ArrowTrendingUpIcon,
	CodeBracketIcon,
	BuildingOfficeIcon,
	ChartBarIcon,
	ArrowUpTrayIcon,
	UserGroupIcon,
	NoSymbolIcon,
	ClipboardDocumentListIcon,
	ArrowUturnUpIcon,
	BellAlertIcon,
	PhoneIcon,
	PaperAirplaneIcon,
	ClockIcon,
	AdjustmentsVerticalIcon,
	DocumentTextIcon,
	ChatBubbleLeftRightIcon,
	ListBulletIcon,
	PlusIcon,
	ArrowTopRightOnSquareIcon,
	BanknotesIcon,
	// DocumentChartBarIcon,
	ClipboardDocumentCheckIcon,
	HashtagIcon,
	ArrowDownTrayIcon,
	// Cog6ToothIcon,
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import useEnterpriseOwner from 'hooks/data/useEnterpriseOwner'
import userAuthenticationState from 'logic/auth'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'
import Topheader from './TopHeader'
import { useAppContext } from '../AppProvider'
import WhatsappIcon from './WhatsAppIcon'
import { Cog6ToothIcon } from '@heroicons/react/24/solid'

type MenuLink = {
	name: string
	to: string
	count?: number
	icon: any
}

export type HeaderLink = {
	name: string
	to: string
	icon: any
	isHidden?: boolean
	menuLinks?: MenuLink[]
	isExternal?: boolean
	count?: number
	isHighlighted?: boolean
}

export type Navigation = {
	name: string
	to: string
	icon: any
	isHidden?: boolean
	headerLinks?: HeaderLink[]
	isNewFeature?: boolean
	isBetaFeature?: boolean
	// Takes precedence over other tag messages, e.g. New, Beta
	customTagMessage?: string
}

export default function DashboardLayout({ children }: { children: any }): any {
	const [sidebarOpen, setSidebarOpen] = useState(false)
	const { t } = useTranslation(['header'])
	const enterpriseOwnerStatus = useEnterpriseOwner()
	const authState = userAuthenticationState()

	const { count } = useAppContext()

	if (authState.state === 'unauthenticated') {
		return <main className='py-[4rem]'>{children}</main>
	}

	const navigation: Navigation[] = [
		{
			name: t('authenticatedLinks.SMS'),
			to: '/sendsms',
			icon: EnvelopeIcon,
			headerLinks: [
				{ name: t('authenticatedLinks.sendSMS'), to: '/sendsms/compose', icon: ChatBubbleBottomCenterTextIcon },
				{
					name: t('authenticatedLinks.inbox'),
					count: count.smsInbox,
					to: '/sendsms/inbox',
					icon: InboxArrowDownIcon,
				},
				{
					name: t('authenticatedLinks.outbox'),
					to: '/sendsms/outbox',
					icon: ArrowUpTrayIcon,
					menuLinks: [
						{
							name: t('authenticatedLinks.sent'),
							count: count.smsSent,
							to: '/sendsms/outbox/sent',
							icon: InboxArrowDownIcon,
						},
						{
							name: t('authenticatedLinks.scheduled'),
							count: count.smsScheduled,
							to: '/sendsms/outbox/scheduled',
							icon: InboxArrowDownIcon,
						},
						{
							name: t('authenticatedLinks.attachments'),
							count: count.smsAttachments,
							to: '/sendsms/outbox/attachments',
							icon: InboxArrowDownIcon,
						},
						{
							name: t('authenticatedLinks.recipientReports'),
							count: count.smsRecipients,
							to: '/sendsms/outbox/recipient-reports',
							icon: InboxArrowDownIcon,
						},
					],
				},
			],
		},
		{
			name: 'Whatsapp',
			to: '/whatsapp',
			icon: WhatsappIcon,
			isNewFeature: false,
			isHidden: false,
			isBetaFeature: false,
			customTagMessage: '',
			headerLinks: [
				{ name: 'Send Whatsapp', to: '/whatsapp', icon: ChatBubbleBottomCenterTextIcon },
				{ name: 'Outbox', to: '/whatsapp/outbox', icon: ArrowUpTrayIcon },
				{ name: 'Inbox', to: '/whatsapp/inbox', icon: ArrowDownTrayIcon },
				{ name: 'Settings', to: '/whatsapp/settings', icon: Cog6ToothIcon },
				// {
				// 	name: 'Setup Your Number',
				// 	to: '/whatsapp/settings',
				// 	icon: PhoneIcon,
				// 	isHighlighted: true,
				// },
				// {
				// 	name: 'Manage Templates',
				// 	count: count.smsInbox,
				// 	to: '/whatsapp/templates',
				// 	icon: DocumentTextIcon,
				// },
			],
		},
		{
			name: t('authenticatedLinks.contacts'),
			to: '/contacts',
			icon: UsersIcon,
			headerLinks: [
				{
					name: t('authenticatedLinks.allContacts'),
					count: count.contactList,
					to: '/contacts',
					icon: ClipboardDocumentListIcon,
				},
				{
					name: t('authenticatedLinks.groups'),
					count: count.contactGroups,
					to: '/contacts/groups',
					icon: UserGroupIcon,
				},
				{
					name: t('authenticatedLinks.blocklist'),
					count: count.contactBlockList,
					to: '/contacts/blocklist',
					icon: NoSymbolIcon,
				},
			],
		},
		{
			name: t('authenticatedLinks.api'),
			to: '/api-keys',
			icon: CodeBracketIcon,
			headerLinks: [
				{
					name: t('authenticatedLinks.apiDocumentation'),
					to: 'https://developers.smsleopard.com/how-our-sms-api-works',
					icon: ArrowTopRightOnSquareIcon,
					isExternal: true,
				},
			],
		},
		{
			name: t('authenticatedLinks.airtime'),
			to: '/airtime',
			icon: PhoneIcon,
			headerLinks: [
				{ name: t('authenticatedLinks.sendAirtime'), to: '/airtime', icon: PaperAirplaneIcon },
				{
					name: t('authenticatedLinks.sent'),
					count: count.airtimeSent,
					to: '/airtime/outbox',
					icon: ClipboardDocumentListIcon,
				},
				{
					name: t('authenticatedLinks.scheduled'),
					count: count.airtimeScheduled,
					to: '/airtime/scheduled',
					icon: ClockIcon,
				},
			],
		},
		{
			name: t('authenticatedLinks.bulkData'),
			to: '/bulk-data',
			icon: ChartBarIcon,
			headerLinks: [
				{ name: t('authenticatedLinks.sendData'), to: '/bulk-data', icon: PaperAirplaneIcon },
				{
					name: t('authenticatedLinks.sent'),
					count: count.bulkDataSent,
					to: '/bulk-data/outbox',
					icon: ClipboardDocumentListIcon,
				},
				{
					name: t('authenticatedLinks.scheduled'),
					count: count.bulkDataScheduled,
					to: '/bulk-data/scheduled',
					icon: ClockIcon,
				},
			],
		},
		{
			name: t('authenticatedLinks.sendMoney'),
			to: '/send-money',
			icon: BanknotesIcon,
			isHidden: true,
			isNewFeature: true,
			headerLinks: [
				{ name: t('authenticatedLinks.sendMoney'), to: '/send-money', icon: PaperAirplaneIcon },
				{
					name: t('authenticatedLinks.sent'),
					to: '/send-money/sent',
					icon: ClipboardDocumentListIcon,
				},
				// {
				// 	name: t('authenticatedLinks.reports'),
				// 	to: '/send-money/recipient-reports',
				// 	icon: DocumentChartBarIcon,
				// },
				{
					name: t('authenticatedLinks.pendingApproval'),
					to: '/send-money/pending-approval-drafts',
					icon: ClockIcon,
				},
				{
					name: t('authenticatedLinks.approvedDrafts'),
					to: '/send-money/approved-drafts',
					icon: ClipboardDocumentCheckIcon,
				},
			],
		},
		{
			name: t('authenticatedLinks.surveys'),
			to: '/surveys',
			icon: ChartPieIcon,
			isNewFeature: false,
			headerLinks: [
				{ name: t('authenticatedLinks.surveys'), count: count.surveyListCount, to: '/surveys', icon: ListBulletIcon },
				{ name: t('authenticatedLinks.outbox'), to: '/surveys/outbox', icon: ArrowUpTrayIcon },
				{
					name: t('authenticatedLinks.surveyDetails'),
					to: '/surveys/new',
					icon: PlusIcon,
					isHighlighted: true,
				},
			],
		},
		{
			name: 'USSD',
			to: '/ussd',
			icon: HashtagIcon,
		},
		{
			name: t('authenticatedLinks.billing'),
			to: '/billing',
			icon: CreditCardIcon,
			headerLinks: [
				{
					name: t('authenticatedLinks.transactions'),
					count: count.billingTransactions,
					to: '/billing/transactions',
					icon: ClipboardDocumentListIcon,
				},
				{ name: t('authenticatedLinks.accountTopUp'), to: '/billing/topup', icon: ArrowUturnUpIcon },
				{ name: t('authenticatedLinks.lowBalanceAlerts'), to: '/billing/lowbalance', icon: BellAlertIcon },
			],
		},
		// { name: t('authenticatedLinks.analytics'), to: '/analytics', icon: ArrowTrendingUpIcon },
		{
			name: t('authenticatedLinks.businessAccount'),
			to: '/business-account',
			icon: BuildingOfficeIcon,
			isHidden: enterpriseOwnerStatus !== 'isOwner',
			headerLinks: [
				{
					name: t('authenticatedLinks.accounts'),
					count: count.businessAccountsList,
					to: '/business-account',
					icon: ListBulletIcon,
				},
				{
					name: t('authenticatedLinks.users'),
					count: count.businessAccountsUsers,
					to: '/business-account/users',
					icon: UsersIcon,
				},
				{
					name: t('authenticatedLinks.messages'),
					count: count.businessAccountsMessages,
					to: '/business-account/messages',
					icon: ChatBubbleLeftRightIcon,
				},
				{ name: t('authenticatedLinks.summary'), to: '/business-account/summary', icon: DocumentTextIcon },
				{ name: t('authenticatedLinks.settings'), to: '/business-account/settings', icon: AdjustmentsVerticalIcon },
			],
		},
	]

	return (
		<>
			<div>
				<MobileNavigation navigation={navigation} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				<DesktopNavigation navigation={navigation} />

				<div className='lg:pl-[28.8rem]'>
					<Topheader setSidebarOpen={setSidebarOpen} navigation={navigation} />

					<main className='py-[4rem]'>{children}</main>
				</div>
			</div>
		</>
	)
}
