import cn from 'classnames'
import { NavLink } from 'react-router-dom'
import { Cog6ToothIcon, EyeIcon } from '@heroicons/react/24/outline'
import type { Navigation } from './DashboardLayout'
import useEnterpriseOwner from 'hooks/data/useEnterpriseOwner'

const DesktopNavigation = ({ navigation }: { navigation: Navigation[] }): any => {
	const orgStatus = useEnterpriseOwner()
	return (
		<div className='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-[28.8rem] lg:flex-col'>
			<div className='flex grow flex-col gap-y-[2rem] overflow-y-auto border-r border-gray-200 bg-white px-[2.4rem] pb-[1.6rem]'>
				<div className='flex h-[6.4rem] shrink-0 items-center'>
					<NavLink to='/'>
						<img className='h-[3.2rem] w-auto' src='/img/blue-logo.svg' alt='SMSLeopard logo' />
					</NavLink>
				</div>
				<nav className='flex flex-1 flex-col'>
					<div className='flex flex-1 flex-col gap-y-[2.8rem]'>
						<ul className='-mx-[0.8rem] space-y-[0.4rem] flex flex-col h-full'>
							{navigation.map(item => {
								if (item.isHidden) return null
								return (
									<li key={item.name}>
										<NavLink
											to={item.to}
											activeClassName='bg-gray-50 text-primary'
											className={cn(
												'text-gray-700 hover:text-primary hover:bg-gray-50',
												'group flex gap-x-[1.2rem] rounded-[0.6rem] p-[0.8rem] text-[1.4rem] leading-[2.4rem] font-semibold',
											)}>
											<item.icon className='h-[2.4rem] w-[2.4rem] shrink-0' aria-hidden='true' />
											{item.name}
											{item.customTagMessage ? (
												<span className='inline-flex items-center gap-x-[0.8rem] rounded-full bg-purple-100 px-[1.2rem] py-[0.1rem] text-[1.2rem] font-medium text-purple-700'>
													<svg
														className='h-[0.6rem] w-[0.6rem] fill-purple-500'
														viewBox='0 0 6 6'
														aria-hidden='true'>
														<circle cx={3} cy={3} r={3} />
													</svg>
													{item.customTagMessage}
												</span>
											) : (
												<>
													{item.isNewFeature && !item.isBetaFeature && (
														<span className='inline-flex items-center gap-x-[0.8rem] rounded-full bg-green-100 px-[1.2rem] py-[0.1rem] text-[1.2rem] font-medium text-green-700'>
															<svg
																className='h-[0.6rem] w-[0.6rem] fill-green-500'
																viewBox='0 0 6 6'
																aria-hidden='true'>
																<circle cx={3} cy={3} r={3} />
															</svg>
															New
														</span>
													)}
													{item.isBetaFeature && (
														<span className='inline-flex items-center gap-x-[0.8rem] rounded-full bg-orange-100 px-[1.2rem] py-[0.1rem] text-[1.2rem] font-medium text-orange-700'>
															<svg
																className='h-[0.6rem] w-[0.6rem] fill-orange-500'
																viewBox='0 0 6 6'
																aria-hidden='true'>
																<circle cx={3} cy={3} r={3} />
															</svg>
															Beta
														</span>
													)}
												</>
											)}
										</NavLink>
									</li>
								)
							})}
							<li className='flex-1'>
								<NavLink
									to='/settings'
									activeClassName='bg-gray-50 text-primary'
									className={cn(
										'text-gray-700 hover:text-primary hover:bg-gray-50',
										'group flex gap-x-[1.2rem] rounded-[0.6rem] p-[0.8rem] text-[1.4rem] leading-[2.4rem] font-semibold',
									)}>
									<Cog6ToothIcon className='h-[2.4rem] w-[2.4rem] shrink-0' aria-hidden='true' />
									Settings
								</NavLink>
							</li>
							{orgStatus === 'isOwner' && (
								<li>
									<NavLink
										to='/audit-logs'
										activeClassName='bg-gray-50 text-primary'
										className={cn(
											'text-gray-700 hover:text-primary hover:bg-gray-50',
											'group flex items-center gap-x-[1rem] rounded-[0.6rem] p-[0.8rem] text-[rem] leading-[2.4rem] font-semibold',
										)}>
										<EyeIcon className='h-[1.8rem] w-[1.8rem] shrink-0' aria-hidden='true' />
										Audit Logs
									</NavLink>
								</li>
							)}
						</ul>
					</div>
				</nav>
			</div>
		</div>
	)
}

export default DesktopNavigation
