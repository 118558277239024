// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { type Element, type Node, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { Checkbox, Select } from '../FormElements'
import { IconAction } from '../Icon'
import './style.sass'
import type { PaginationParams } from 'types/api/requests/common'

export const DataTable = ({ children }: { children: Node }): Node => {
	return children
}

type Evt = {
	target: {
		value: string,
	},
}
type DataTableHeaderReturnType = ({
	onSearch: (e: Evt) => void,
}) => Element<any>

type FooterProps = {
	params: PaginationParams,
	pageBack: () => void,
	pageForth: () => void,
	onChangeCurrentRow?: (SyntheticInputEvent<HTMLInputElement>) => void,
	showRowsPerPage?: boolean,
	rowsPerPageOptions?: number[],
	FooterActions?: () => Element<any>,
}

export const DataTableHeader = (props: {
	onChange: (string, Object) => void,
	render: DataTableHeaderReturnType,
}): Element<any> => {
	const onSearch = e => {
		const searchQuery = e.target.value
		// On change should call the API and apply selected query params
		props.onChange('search', { search: searchQuery })
	}

	return props.render({
		onSearch,
	})
}

type DataTableContentProps = {
	className?: string,
	columns: Array<{ name: string }>,
	sort?: { sortBy?: Object, sortDirection?: Object },
	children: Node,
	footerProps: null | FooterProps,
	selectionProps: {
		selectAllInPage: Object => void,
		ariaLabel: string,
	} | null,
}

export const DataTableContent = (props: DataTableContentProps): React$Element<'div'> => {
	const { className = '', footerProps } = props
	const { t } = useTranslation(['ui-common/table'])
	const cs = cx('DataTableContainer', className)

	return (
		<div className={cs}>
			<table className='DataTable'>
				<thead className='DataTable__header'>
					<tr>
						{props.selectionProps && (
							<td className='DataTable__header-item DataTable__header-item--selection'>
								<Checkbox
									onChange={props.selectionProps.selectAllInPage}
									aria-label={props.selectionProps.ariaLabel}
								/>
							</td>
						)}
						{props.columns.map(column => {
							return (
								<th className='DataTable__header-item' key={column.name}>
									{column.name}
								</th>
							)
						})}
						{props.selectionProps && <th className='DataTable__header-item'>{t('table.header.actions')}</th>}
					</tr>
				</thead>
				<tbody initial='hidden' animate='visible' className='DataTable__content'>
					{props.children}
				</tbody>
			</table>
			{footerProps && (
				<TableFooter
					params={footerProps.params}
					FooterActions={footerProps.FooterActions}
					onChangeCurrentRow={footerProps.onChangeCurrentRow}
					pageBack={footerProps.pageBack}
					pageForth={footerProps.pageForth}
					showRowsPerPage={footerProps.showRowsPerPage}
					rowsPerPageOptions={footerProps.rowsPerPageOptions}
				/>
			)}
		</div>
	)
}

const TableFooter = (props: FooterProps) => {
	const { showRowsPerPage = true, rowsPerPageOptions = [10, 25, 50], onChangeCurrentRow = () => {}, FooterActions } = props
	const { t } = useTranslation(['ui-common/table'])
	return (
		<div className='DataTableFooter'>
			<div className='DataTableFooter__paginationContainer'>
				<div className='DataTableFooter__pagination_icons'>
					<IconAction
						withSiblings
						height={18}
						name='chevron-left'
						aria-label={t('footer.actions.previousPage.label')}
						className='Footer__Pagination__Icon'
						onClick={props.pageBack}
						disabled={!Boolean(props.params.prev_page)}
					/>
					<IconAction
						withSiblings
						height={18}
						name='chevron-right'
						aria-label={t('footer.actions.nextPage.label')}
						className='Footer__Pagination__Icon'
						onClick={props.pageForth}
						disabled={!Boolean(props.params.next_page)}
					/>
				</div>
				<span>
					{t('footer.actions.currentPage.beforeDivider')}{' '}
					<span className='DataTableFooter__page'>{props.params.page}</span>{' '}
					{t('footer.actions.currentPage.afterDivider')}{' '}
					<span className='DataTableFooter__page'>{props.params.num_pages}</span>
				</span>
			</div>
			{showRowsPerPage && (
				<div className='DataTableFooter__RowsCustomizer'>
					<Select
						standalone
						value={props.params.per}
						aria-label={t('footer.actions.rowsPerPage.ariaLabel')}
						className='DataTableFooter__RowsCustomizer__SelectBox'
						onChange={onChangeCurrentRow}>
						{rowsPerPageOptions.map((row, idx) => (
							<option key={`${row}-${idx}`} value={row}>
								{row}
							</option>
						))}
					</Select>
					<p className='DataTableFooter__RowsCustomizer__Title'>{t('footer.actions.rowsPerPage.label')}</p>
				</div>
			)}
			{FooterActions && <FooterActions />}
		</div>
	)
}

type MobileCheckBoxProps = {
	selectAllInPage: (SyntheticInputEvent<HTMLInputElement>) => void,
	ariaLabel: string,
	label: string,
} | null

type DataTableMobileActionsType = {
	pageBack: () => void,
	pageForth: () => void,
	className?: string,
	checkboxProps: MobileCheckBoxProps,
	params: PaginationParams,
}

export const DataTableMobileActions = ({
	pageBack,
	pageForth,
	className = '',
	checkboxProps,
	params,
}: DataTableMobileActionsType): React$Element<'div'> => {
	const cs = cx('DataTableMobileActionsContainer', className, {
		'DataTableMobileActionsContainer--nocheckbox': !checkboxProps,
	})
	const { t } = useTranslation(['ui-common/table'])

	return (
		<div className={cs}>
			{checkboxProps && (
				<div>
					<Checkbox
						onChange={checkboxProps.selectAllInPage}
						label={
							<>
								<p className='DataTableMobileActionsContainer__Checkbox__Label'>{checkboxProps.label}</p>
							</>
						}
						aria-label={checkboxProps.ariaLabel}
					/>
				</div>
			)}
			<div className='DataTableMobileActionsContainer--Pagination Pagination'>
				<IconAction
					aria-label={t('footer.actions.previousPage.label')}
					disabled={!Boolean(params.prev_page)}
					name='chevron-left'
					className='Pagination__Icon Footer__Pagination__Icon'
					onClick={pageBack}
				/>
				<IconAction
					disabled={!Boolean(params.next_page)}
					name='chevron-right'
					className='Pagination__Icon Footer__Pagination__Icon'
					onClick={pageForth}
					aria-label={t('footer.actions.nextPage.label')}
				/>
				<p className='Pagination__Content'>
					{t('footer.actions.currentPage.beforeDivider')}{' '}
					<span className='Pagination__Content__Span'>{params.page}</span>{' '}
					{t('footer.actions.currentPage.afterDivider')}{' '}
					<span className='Pagination__Content__Span'>{params.num_pages}</span>
				</p>
			</div>
		</div>
	)
}

type DataTableFooterActionsType = {
	resetSelected: () => void,
	className?: string,
	children: Node,
}
export const DataTableFooterActions = ({
	resetSelected,
	className = '',
	children,
}: DataTableFooterActionsType): React$Element<'div'> => {
	const cs = cx('FooterActions--container', className)
	return (
		<div className={cs}>
			<IconAction name='close' className='FooterActions--container--close' onClick={resetSelected}></IconAction>
			{children}
		</div>
	)
}

type DataTableDesktopRowType = {
	children: Node,
	error?: boolean,
	className?: string,
	rest?: Object,
}

export const DataTableDesktopRow = ({
	children,
	error = false,
	className = '',
	...rest
}: DataTableDesktopRowType): React$Element<'tr'> => {
	const cs: string = cx('DataTable__content--common', className, {
		'DataTable__content-row': !Boolean(error),
		'DataTable__content-error': Boolean(error),
	})
	return (
		<tr {...rest} className={cs}>
			{children}
		</tr>
	)
}

export const DataTableDesktopTableData = ({
	children,
	className,
	blue,
	...rest
}: {
	children: Node,
	blue?: boolean,
	rest?: Object,
	className?: string,
}): React$Element<'td'> => {
	const cs = cx('DataTable__content-item', className, {
		'DataTable__content-item-blue': blue,
	})

	const isNodeValueBlank = useMemo(() => {
		if (children == null || children === '') return true
		return false
	}, [children])

	const contentToBeRendered = useMemo(() => {
		if (typeof children === 'string') {
			return children.length > 50
				? {
						children: children.substring(0, 50) + '...',
						title: children,
				  }
				: {
						children,
						title: '',
				  }
		}
		return {
			children,
			title: '',
		}
	}, [children])
	return (
		<td {...rest} title={contentToBeRendered.title} className={cs}>
			{isNodeValueBlank ? <>{`\u2014`}</> : contentToBeRendered.children}{' '}
		</td>
	)
}
export const DataTableDesktopActions = ({ children }: { children: Node }): React$Element<'td'> => (
	<td className='DataTable__content-item'>
		<div className='DataTable__hoverActions'>{children}</div>
	</td>
)

// Mobile Components

type MobileDataContainerType = {
	className?: string,
	children: Node,
	footerProps: null | FooterProps,
	selectionProps: MobileCheckBoxProps,
	mobileActionsClassName?: string,
}
export const MobileDataContainer = ({
	children,
	className,
	footerProps,
	selectionProps,
	mobileActionsClassName,
}: MobileDataContainerType): React$Element<React$FragmentType> => {
	const cs: string = cx('MobileListContainer', className)
	const mobileNavClassName: string = cx('MobileListContainer__ActionsContainer', mobileActionsClassName)
	return (
		<>
			{footerProps && (
				<div className={mobileNavClassName}>
					<DataTableMobileActions
						params={footerProps.params}
						checkboxProps={selectionProps}
						pageBack={footerProps.pageBack}
						pageForth={footerProps.pageForth}
					/>
				</div>
			)}
			<ul className={cs}>{children}</ul>
			{footerProps && (
				<TableFooter
					params={footerProps.params}
					FooterActions={footerProps.FooterActions}
					onChangeCurrentRow={footerProps.onChangeCurrentRow}
					pageBack={footerProps.pageBack}
					pageForth={footerProps.pageForth}
					rowsPerPageOptions={footerProps.rowsPerPageOptions}
					showRowsPerPage={footerProps.showRowsPerPage}
				/>
			)}
		</>
	)
}

type DataTableMobileDataRowType = {
	className?: string,
	tableDataClassName?: string,
	error?: boolean,
	header?: Node,
	body?: Node,
	subinfo?: Node,
	checked?: ?boolean,
	callback?: (obj: any) => void,
	hideCheckbox?: boolean,
	checkboxAriaLabel?: string,
	checkboxProps: {
		ariaLabel: string,
		handleToggleItem: (SyntheticInputEvent<HTMLInputElement>) => void,
	} | null,
}

export const DataTableMobileDataRow = ({
	className = '',
	tableDataClassName = '',
	error = false,
	header,
	body,
	subinfo,
	checked,
	callback = () => () => {},
	hideCheckbox,
	checkboxProps,
	...rest
}: DataTableMobileDataRowType): React$Element<'li'> => {
	const cs: string = cx('DataTable__content--common DataTable__content--mobile', className, {
		'DataTable__content-row': !Boolean(error),
		'DataTable__content-error': Boolean(error),
	})

	const tableDataContentClassName = cx('DataTable__content--container', tableDataClassName)

	return (
		<li className={cs}>
			{checkboxProps && (
				<div className='DataTable__content--mobileCheckbox'>
					<Checkbox aria-label={checkboxProps.ariaLabel} checked={checked} onChange={checkboxProps.handleToggleItem} />
				</div>
			)}
			<div style={{ width: '100%' }} className={tableDataContentClassName} onClick={callback}>
				<div className='DataTable__content--mobileContentContainer'>
					{header}
					<p className='DataTable__content--mobileContentContainer--body'>{body}</p>
					<p>{subinfo}</p>
				</div>
			</div>
		</li>
	)
}

type DataTableMobileBoldHeaderType = DataTableDesktopRowType

export const DataTableMobileBoldHeader = ({
	children,
	error = false,
	...rest
}: DataTableMobileBoldHeaderType): React$Element<'b'> => {
	const cs: string = cx('DataTable__content--boldheader', {
		'DataTable__content--boldheader--error': Boolean(error),
	})
	return (
		<b className={cs} {...rest}>
			{children}
		</b>
	)
}
