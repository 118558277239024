import { lazy } from 'react'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from 'website'

const Whatsapp = lazy(() => import(/* webpackChunkName: "Whatsapp" */ '../Whatsapp'))
const WhatsappConfiguration = lazy(
	() => import(/* webpackChunkName: "Whatsapp" */ '../Whatsapp/SendWhatsapp/views/WhatsappConfiguration'),
)
const SendWhatsapp = lazy(() => import(/* webpackChunkName: "SendWhatsapp" */ '../Whatsapp/SendWhatsapp'))
const WhatsappTransactionDetails = lazy(
	() => import(/* webpackChunkName: "Whatsapp-details-page" */ '../Whatsapp/TransactionDetails'),
)

const WhatsappRoutes = (props: React.JSX.IntrinsicAttributes & { [x: string]: any; component: any }) => {
	return (
		<Switch>
			<PrivateRoute {...props} path='/whatsapp/scheduled/:id' component={WhatsappTransactionDetails} />
			<PrivateRoute {...props} path='/whatsapp' exact component={SendWhatsapp} />
			<PrivateRoute {...props} path='/whatsapp/settings' exact component={WhatsappConfiguration} />
			<PrivateRoute {...props} path='/whatsapp/settings/:phone_number_id' exact component={WhatsappConfiguration} />
			<PrivateRoute {...props} path='/whatsapp/:section' exact component={Whatsapp} />
			<PrivateRoute {...props} path='/whatsapp/:section/:id' component={WhatsappTransactionDetails} />
		</Switch>
	)
}

export default WhatsappRoutes
